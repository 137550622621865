<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 682.34 757.28">
    <g>
      <path
        fill="currentColor"
        stroke="0"
        d="M0,416.06c0-68.79,0-137.59,0-206.38,0-60.19,39.46-112.64,97.29-129.55,4.8-1.4,6.45-1.06,6.27,4.59-.43,13.25-.08,26.53-.13,39.79-.09,27.01,20.46,48.41,46.67,48.54,26.35.12,47.13-20.82,47.35-47.88.12-15.16.12-30.32-.05-45.48-.04-3.62.93-4.86,4.74-4.85,92.67.1,185.35.1,278.02,0,3.78,0,4.8,1.19,4.75,4.83-.19,14.97-.28,29.94-.08,44.91.26,19.19,8.46,34.17,25.55,43.16,16.26,8.54,32.7,7.47,47.88-2.94,13.35-9.15,20.27-22.29,20.56-38.55.25-14.21.24-28.43-.04-42.64-.09-4.5,1.41-4.98,5.12-3.79,52.13,16.63,84.46,51.38,96.13,105,1.86,8.54,2.29,17.24,2.29,25.99-.04,136.64-.02,273.28-.03,409.92,0,68.62-46.61,123.68-114.38,135.03-6.53,1.09-13.16,1.53-19.81,1.53-137.97-.03-275.93.05-413.9-.05C60.13,757.18.1,697.01.02,623.01c-.04-34.49,0-68.98,0-103.48,0-34.49,0-68.98,0-103.47ZM341.47,271.64c-89.26,0-178.53.04-267.79-.11-5.13,0-6.57,1.23-6.57,6.5.16,114.47.1,228.94.13,343.41,0,38.52,29.35,68.5,67.79,68.54,137.4.16,274.81.15,412.21,0,38.43-.04,67.83-30,67.84-68.49.03-114.47-.02-228.94.13-343.41,0-5.16-1.27-6.57-6.51-6.56-89.08.17-178.15.12-267.23.12Z"
      />
      <path
        fill="currentColor"
        stroke="0"
        d="M178.95,77.55c0,15.71.05,31.43-.02,47.14-.07,17.57-12.06,30.07-28.64,30.02-16.39-.05-28.45-12.79-28.48-30.28-.06-31.99-.16-63.99.03-95.98.09-14.72,10.97-26.52,25.3-28.24,13.78-1.66,26.88,7.14,30.8,20.64.96,3.33,1.01,6.72,1.01,10.13,0,15.52,0,31.05,0,46.57Z"
      />
      <path
        fill="currentColor"
        stroke="0"
        d="M503.37,77c0-15.52-.05-31.05.01-46.57.07-17.6,11.98-30.29,28.37-30.36,16.6-.07,28.76,12.79,28.79,30.62.05,31.8.11,63.61-.05,95.41-.07,14.5-10.7,26.53-24.49,28.41-14.21,1.93-27.52-6.62-31.56-20.25-1.15-3.87-1.09-7.86-1.1-11.83-.03-15.14-.01-30.29-.01-45.43.01,0,.02,0,.03,0Z"
      />
    </g>
    <g>
      <rect
        fill="currentColor"
        stroke="0"
        x="161.13"
        y="348.14"
        width="160.68"
        height="254.41"
        rx="12"
        ry="12"
      />
      <rect
        fill="currentColor"
        stroke="0"
        x="348.59"
        y="348.14"
        width="160.68"
        height="254.41"
        rx="12"
        ry="12"
      />
    </g>
  </svg>
</template>
